import { sponsoredContentTeaserIsHighlighted, storyblockTeaserSizesConfig } from '@hubcms/brand';

import { isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import { type GDefaultTeaser, type GList, createTitle, GListTeaser } from '@hubcms/domain-teaser-grid';
import type { OpeningGroupOptions } from '@hubcms/domain-storyblock';

import { getTeaserSize } from '../getTeaserSize';
import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';
import { isArticleOrPlaceholderTeaserData } from '../../internal/isArticleOrPlaceholderTeaserData';

const getOpeningTeaserSizes = getTeaserSize(storyblockTeaserSizesConfig, 'opening');

export const mapOpeningGroup: MapStoryblockFn<OpeningGroupOptions> = (storyblockOptions, teaserAreas) => {
  const numberOfTeasers = 3;
  const isSponsoredBlock = storyblockOptions.theme === 'sponsored-content';
  const headHasIntro = !storyblockOptions.isIntroHidden;

  const items: Array<GDefaultTeaser | GList> = teaserAreas.newsArea
    .filter(isArticleOrPlaceholderTeaserData)
    .slice(0, numberOfTeasers)
    .map((teaserData, idx, arr) => {
      const isHeadTeaser = idx === 0;

      const isPlaceholder = isPlaceholderTeaserData(teaserData);
      const isSponsoredTeaser = !isPlaceholder && teaserData.theme === 'sponsored-content';
      const hasSponsoredThemeOnTeaserLevel = !isSponsoredBlock && isSponsoredTeaser;
      const isHighlighted = hasSponsoredThemeOnTeaserLevel && sponsoredContentTeaserIsHighlighted;

      return {
        type: 'default-teaser',
        data: {
          hasLabel: true,
          teaserData,
          imageSizes: {
            xs: isHeadTeaser ? '100vw' : '50vw',
            lg: isHeadTeaser ? '632px' : '308px',
          },
          hasIntro: isHeadTeaser && headHasIntro,
          isPriority: isHeadTeaser,
          hasDateTime: false,
          theme: hasSponsoredThemeOnTeaserLevel ? 'sponsored-content' : undefined,
          isHighlighted,
          hasInset: isHighlighted || (isHeadTeaser ? { xs: true, lg: false } : false),
          orientation: {
            xs: isHeadTeaser ? 'vertical' : 'horizontal',
            md: 'vertical',
          },
          size: getOpeningTeaserSizes(isHeadTeaser),
          headerLevel: 3,
          trackingData: {
            ...(!isPlaceholder ? teaserData.trackingData : {}),
            clickitemblock: storyblockOptions.newsAreaOptions.title,
            clickitemposition: idx + 1,
          },
        },
        gridProps: isHeadTeaser
          ? {
              columnMd: 'span 2',
              rowLg: headHasIntro ? 'span 3' : 'span 2',
              hasInverseMarginInlineSm: true,
              hasInverseMarginInlineMd: true,
              hasInverseMarginBlockEndSm: true,
            }
          : {
              columnLg: '3 / 4',
              hasNoGridDivider: idx === arr.length - 1,
            },
      };
    });

  const newsAreaOptions = storyblockOptions.newsAreaOptions;
  const newsTitle = createTitle(newsAreaOptions.title, newsAreaOptions.actionLink, newsAreaOptions.actionText);
  if (newsTitle && newsTitle?.gridProps) {
    newsTitle.gridProps.columnMd = 'span 2';
    newsTitle.gridProps.columnLg = 'span 3';
  }

  const listAreaOptions = storyblockOptions.listAreaOptions;
  const listTitle = createTitle(listAreaOptions.title, listAreaOptions.actionLink, listAreaOptions.actionText);
  const listItems = teaserAreas.listArea;
  if (listTitle) {
    listTitle.data.size = 'sm';
  }

  let lgBlockHeight = 2;
  if (newsAreaOptions.title) {
    lgBlockHeight++;
  }
  if (headHasIntro) {
    lgBlockHeight++;
  }
  const listArea: GList = {
    type: 'list',
    gridProps: {
      columnMd: 'span 2',
      columnLg: '-2',
      rowLg: `1 / span ${lgBlockHeight}`,
    },
    data: {
      title: listTitle,
      layout: listAreaOptions.listLayout,
      isOrdered: listAreaOptions.listLayout === 'Numbering',
      verticalAlign: 'top',
      items: listItems.filter(isArticleOrPlaceholderTeaserData).map((teaserData, idx): GListTeaser => {
        const isPlaceholder = isPlaceholderTeaserData(teaserData);
        return {
          type: 'list-teaser',
          data: {
            teaserData,
            hasDateTime: listAreaOptions.listLayout === 'PublishedDate',
            headerLevel: 5,
            trackingData: {
              ...(!isPlaceholder ? teaserData.trackingData : {}),
              clickitemblock: listAreaOptions.title,
              clickitemposition: idx + 1,
            },
          },
        };
      }),
    },
  };

  return {
    key: 'newsArea',
    gridAutoRows: 'min-content',
    title: newsTitle,
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
    items: items.length ? items.concat([listArea]) : [],
  };
};
