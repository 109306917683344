import { StoryblockTeaserSizesConfig } from '@hubcms/domain-storyblock';
import { TeaserSizes } from '@hubcms/domain-teaser';

export function getTeaserSize<T extends string = never>(
  config: StoryblockTeaserSizesConfig<T>,
  key: keyof StoryblockTeaserSizesConfig<T>,
) {
  const value = config[key];
  return (isHero: boolean, variant?: boolean): TeaserSizes => {
    if (!value) {
      return 'md';
    }
    if (typeof value === 'function') {
      return value(isHero, variant);
    }
    return value;
  };
}
