import { ArticleTeaserData } from '@hubcms/domain-teaser';
import { TrackingData } from '@hubcms/domain-tracking';
import { getArticleAuthor, getArticleType } from '@hubcms/utils-tracking';

export function appendDefaultArticleTrackingData(articleTeaserData: ArticleTeaserData): ArticleTeaserData {
  const trackingData: TrackingData = {
    ...articleTeaserData.trackingData,
    articleauthor: getArticleAuthor(articleTeaserData.authors),
    articleid: articleTeaserData.id,
    articletype: getArticleType(articleTeaserData.contentType),
    articlepublicationdateinutc: articleTeaserData.dateTime,
    clickitemname: articleTeaserData.title,
    clickitemurl: articleTeaserData.url,
    pagesecuretype: articleTeaserData.isPremium ? 'plus' : 'open',
  };

  return {
    ...articleTeaserData,
    trackingData,
  };
}
