import { PartialRequiredTeaser } from '../util-types/partial-required-teaser';
import { TeaserData } from './teaser-data';

export type ListType = 'article-list' | 'personalized-list';

export type TeaserList<Params = unknown> = {
  teaserType: 'list';
  listType: ListType;
  id: string;
  items: TeaserData[];
  params: Params;
  title: string;
};

export function createTeaserList<Params = unknown>(
  partialTeaserList: PartialRequiredTeaser<TeaserList<Params>, 'id' | 'listType' | 'params'>,
): TeaserList<Params> {
  return {
    teaserType: 'list',
    items: [],
    title: '',
    ...partialTeaserList,
  };
}
