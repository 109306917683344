import type {
  ArticleTeaserData,
  HtmlTeaserData,
  NewsletterTeaserData,
  PlaceholderTeaserData,
  TeaserData,
} from '@hubcms/domain-teaser';
import { GGrid, GTitle, TGridProps, TTeaserGridItem } from '@hubcms/domain-teaser-grid';
import type { CustomizableGroupOptions } from '@hubcms/domain-storyblock';

import { hideGridItemOnMobile } from '../../../internal/hideGridItemOnMobile';
import { createListData } from '../../../internal/createListData';
import { createListItems } from '../../../internal/createListItems';
import { createFullAreaAd } from '../../createFullAreaAd';
import { isCustomizableStoryblockTeaserData } from '../../../internal/isCustomizableStoryblockTeaserData';

import { createCustomizableStoryblockTeaser } from './createCustomizableStoryblockTeaser';
import { BaseParams } from './types';
import { createAreaWithCbaAds } from './createAreaWithCbaAds';

type AreaThreeOptions = CustomizableGroupOptions['areaThreeOptions'];

export function mapToAreaThreeItems(
  teaserData: TeaserData[],
  areaThreeOptions: AreaThreeOptions,
  params: BaseParams & {
    areaThreeTitle: GTitle | null;
    isAreaTwoStackedMobile: boolean;
    isAreaOneWithIntro: boolean;
  },
): Array<TTeaserGridItem<string, unknown>> {
  if (params.areaThreeTitle) {
    params.areaThreeTitle.data.size = 'sm';
    if (areaThreeOptions.isAreaHiddenMobile) {
      params.areaThreeTitle.gridProps = hideGridItemOnMobile(params.areaThreeTitle.gridProps);
    }
  }

  if (areaThreeOptions.isList && !areaThreeOptions.hasAd) {
    const listItems = createListItems(teaserData, areaThreeOptions);

    const lgBlockHeight = calculateListHeight(params);
    const listArea = createListData(listItems, areaThreeOptions, lgBlockHeight);
    listArea.gridProps = {
      ...listArea.gridProps,
      column: 'span 2',
      columnMd: 'span 2',
      columnLg: '-2',
    };

    const gridItems: Array<TTeaserGridItem<string, unknown>> = [listArea];

    listArea.data.title = params.areaThreeTitle;

    if (areaThreeOptions.isAreaHiddenMobile) {
      gridItems.forEach(gridItem => {
        gridItem.gridProps = hideGridItemOnMobile(gridItem.gridProps);
      });
    }
    return gridItems;
  }

  const isAreaOnePositionTop = params.areaOnePosition === 'top';
  const isOnlyItem = teaserData.length === 1;
  const gridItems =
    areaThreeOptions.hasAd || areaThreeOptions.hasCbaAd
      ? createAdItems(params, teaserData.filter(isCustomizableStoryblockTeaserData), areaThreeOptions)
      : teaserData
          .filter(isCustomizableStoryblockTeaserData)
          .slice(0, 2)
          .map((teaserData, teaserIdx, teaserArray) => {
            const isLastInArea = teaserIdx === teaserArray.length - 1;
            const teaser = createCustomizableStoryblockTeaser(teaserData, areaThreeOptions, {
              isAreaOnePositionTop,
              isLastInArea,
              isOnlyItem,
            });

            if (teaser && !params.isAreaTwoStackedMobile && areaThreeOptions.isStackedMobile && teaserIdx === 0) {
              teaser.gridProps = {
                ...teaser.gridProps,
                hasInverseMarginBlockStartSm: false,
              };
            }

            return teaser;
          });

  if (!params.areaThreeTitle) {
    return gridItems.map(moveToLastColumn);
  }

  params.areaThreeTitle.gridProps = {
    ...params.areaThreeTitle.gridProps,
    hasInverseMarginBlockEndSm: !(areaThreeOptions.isHighlighted && areaThreeOptions.isStackedMobile),
    hasInverseMarginBlockEndMd: true,
    hasInverseMarginBlockEndLg: true,
    hasNoGridDivider: true,
  };

  if (params.hasGroupTitle) {
    return [params.areaThreeTitle, ...gridItems].map(moveToLastColumn);
  }

  if (areaThreeOptions.isAreaHiddenMobile) {
    params.areaThreeTitle.gridProps = hideGridItemOnMobile(params.areaThreeTitle.gridProps);
  }

  let columnHeight = 2;
  if (isAreaOnePositionTop) {
    columnHeight++;
  }

  const grid: GGrid = {
    type: 'grid',
    data: {
      title: params.areaThreeTitle,
      gridColumns: 2,
      gridColumnsMd: 2,
      gridColumnsLg: 1,
      hasInlinePadding: true,
      items: gridItems,
      height: areaThreeOptions.hasAd ? '100%' : 'auto',
    },
    gridProps: {
      column: 'span 2',
      hasInverseMarginInlineSm: true,
      rowLg: `1 / span ${columnHeight}`,
    },
  };
  return [moveToLastColumn(grid)];
}

function createAdItems(
  params: BaseParams,
  teaserData: Array<HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData | NewsletterTeaserData>,
  areaThreeOptions: AreaThreeOptions,
) {
  if (areaThreeOptions.hasCbaAd) {
    const cbaAreaItems = createAreaWithCbaAds(teaserData, areaThreeOptions, params.areaOnePosition === 'top');

    if (areaThreeOptions.isAreaHiddenMobile) {
      return cbaAreaItems.map(item => {
        item.gridProps = hideGridItemOnMobile(item.gridProps);
        return item;
      });
    }

    return cbaAreaItems;
  }

  return [createFullAreaAdWithGridProps(params, areaThreeOptions)];
}

function createFullAreaAdWithGridProps(params: BaseParams, areaThreeOptions: AreaThreeOptions) {
  const ad = createFullAreaAd(areaThreeOptions.fullAdFormat);
  ad.gridProps = {
    column: 'span 2',
    columnLg: 'auto',
    rowLg: getAdRowLg(params),
  };
  if (areaThreeOptions.isAreaHiddenMobile) {
    ad.gridProps = hideGridItemOnMobile(ad.gridProps);
  }
  return ad;
}

function moveToLastColumn<T extends TTeaserGridItem<string, unknown>>(item: T): T {
  return {
    ...item,
    gridProps: {
      ...item.gridProps,
      columnLg: '-2',
    },
  };
}

function calculateListHeight(
  params: BaseParams & {
    isAreaOneWithIntro: boolean;
  },
): number {
  let lgBlockHeight = 2;

  if (params.hasGroupTitle) {
    lgBlockHeight++;
  }

  if (params.areaOnePosition === 'top') {
    lgBlockHeight++;
  }
  if (params.isAreaOneWithIntro) {
    lgBlockHeight++;
  }
  return lgBlockHeight;
}

function getAdRowLg({ areaOnePosition, hasGroupTitle, hasAreaThreeTitle }: BaseParams): TGridProps['rowLg'] {
  if (hasGroupTitle) {
    return areaOnePosition === 'left' || areaOnePosition === 'middle' ? '2 / 4' : '2 / 5';
  }
  if (hasAreaThreeTitle) {
    return areaOnePosition === 'left' || areaOnePosition === 'middle' ? '2 / 3' : '2 / 4';
  }
  return areaOnePosition === 'left' || areaOnePosition === 'middle' ? '1 / 3' : '1 / 4';
}
