import { AbstractGroup, SectionParams } from '@hubcms/domain-cook';
import { RegularGroupOptions } from '@hubcms/domain-storyblock';
import { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { getGroupLink } from '../../lib/getGroupLink';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';

export const createRegularGroupOptions: CreateStoryblockOptionsFn<RegularGroupOptions> = ({
  group,
  sectionParams,
  canHaveAds,
}) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  const nrOfItems = getNrOfItems(groupOptions['webStoryblock']);
  return {
    type: getType(group.__typename),
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),

    title: groupOptions['groupTitle'],
    actionLink: getGroupLink(groupOptions, 'groupTagLink', 'groupSectionLink'),
    actionText: sectionParams['section.group.readmore.text'],

    ads: canHaveAds ? getAds(groupOptions.showAdvertisement, sectionParams, nrOfItems) : null,
    isIntroHidden: groupOptions['hideIntro'] === 'true',
    isSponsoredGroup: group.__typename === 'SponsoredGroup' || groupOptions['groupTheme'] === 'sponsored-content',
    mobileLayout: {
      carousel: groupOptions['layoutMobileApp'] === 'carousel',
      hasHero: groupOptions['layoutMobileApp'] === 'stackedWithHero',
      isStacked:
        groupOptions['layoutMobileApp'] !== 'carousel' &&
        groupOptions['layoutMobileApp'] !== 'slider' &&
        !groupOptions['webStoryblock']?.includes('WithSlider'),
    },
    nrOfItems,
    teaserType: getTeaserType(groupOptions['teaserType']),
  };
};

function getType(__typename: AbstractGroup['__typename']): RegularGroupOptions['type'] {
  if (__typename === 'RegularGroup' || __typename === 'SponsoredGroup') {
    return __typename;
  }
  return 'RegularGroup';
}

function getNrOfItems(webStoryblock: string): RegularGroupOptions['nrOfItems'] {
  const numberDerivedFromWebStoryblock = Number(webStoryblock.split('-')?.[1]);

  switch (numberDerivedFromWebStoryblock) {
    case 3:
    case 4:
    case 6:
    case 8:
      return numberDerivedFromWebStoryblock;
    default:
      return 4;
  }
}

function getAds(
  showAdvertisement: string,
  sectionParams: SectionParams,
  nrOfItems: RegularGroupOptions['nrOfItems'],
): RegularGroupOptions['ads'] {
  if (!showAdvertisement || showAdvertisement === 'no') {
    return null;
  }

  const adType = showAdvertisement === 'display-cba-on-last' ? 'cba' : 'single';
  const formats =
    adType === 'cba'
      ? sectionParams[`b2b.storyblock.regular.cba.adformat`]
      : sectionParams[`b2b.storyblock.RegularBlock-${nrOfItems}.display.adformat`];
  return {
    position: showAdvertisement === 'display-on-first' ? 'first' : 'last',
    type: adType,
    formats,
  };
}

function getTeaserType(teaserType: string): RegularGroupOptions['teaserType'] {
  if (
    teaserType === 'default' ||
    teaserType === 'textOnImageOneOne' ||
    teaserType === 'textOnImageFourFive' ||
    teaserType === 'textOnImage' // Remove this line in CW-2612
  ) {
    return teaserType;
  }
  return 'default';
}
