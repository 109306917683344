import { AbstractGroup } from '@hubcms/domain-cook';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { mapTeaserFragments } from '@hubcms/utils-teaser';

export function createTeaserAreas(group: AbstractGroup, teaserPropsOptions: TeaserPropsOptions, singleAreaName?: string) {
  return Object.fromEntries(
    group.areaNames
      .filter(areaName => !singleAreaName || areaName === singleAreaName)
      .map(areaName => [areaName, mapTeaserFragments((group as never)[areaName] || [], teaserPropsOptions)]),
  );
}
