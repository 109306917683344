import { ListOptions } from '../domain/list-options';

export function getListLayout(groupOptionsListLayout: string): ListOptions['listLayout'] {
  switch (groupOptionsListLayout) {
    case 'numbering':
    case 'Numbering':
      return 'Numbering';
    case 'publishedDate':
    case 'PublishedDate':
      return 'PublishedDate';
    default:
      return 'none';
  }
}
