import { RecommendationsTeaser } from '@hubcms/domain-capitan';
import { ArticleTeaserData, TeaserImage, TeaserImageSource, createArticleTeaserData } from '@hubcms/domain-teaser';
import { TrackingData } from '@hubcms/domain-tracking';

export type ListIdentifiers = {
  recommender: string;
  listId: string;
};

export const mapRecommendationTeasersToTeaserData = (
  recommendationTeasers: RecommendationsTeaser[],
  { recommender, listId }: ListIdentifiers,
  trackingData?: TrackingData,
): ArticleTeaserData[] => {
  return recommendationTeasers.map(recommendationTeaser =>
    createArticleTeaserData({
      contentType: recommendationTeaser.articleContentType as ArticleTeaserData['contentType'],
      id: recommendationTeaser.articleId,
      list: {
        sourceType: 'recommendation',
        id: listId,
      },
      url: recommender ? `${recommendationTeaser.pageUrl}?recommender=${recommender}` : recommendationTeaser.pageUrl,
      dateTime: recommendationTeaser.articlePublicationDateInUtc,
      image: mapTeaserImage(recommendationTeaser.images),
      introduction: recommendationTeaser.articleIntro,
      isDateToday: false,
      isPremium: recommendationTeaser.isPremium,
      label: recommendationTeaser.articleLabel,
      subLabel: recommendationTeaser.articleSubLabel,
      title: recommendationTeaser.title,
      trackingData,
    }),
  );
};

const imageAspectRatioMapping: { [key in keyof TeaserImage['imageFormats']]: string } = {
  oneOne: 'BASE_ONE_ONE',
  sixteenNine: 'BASE_SIXTEEN_NINE',
  fourFive: 'BASE_FOUR_FIVE',
  fourThree: 'BASE_FOUR_THREE',
  threeTwo: 'BASE_THREE_TWO',
  twentyoneNine: 'BASE_TWENTYONE_NINE',
};

const mapTeaserImage = (recommendationTeaserImages: string[]): TeaserImage | null => {
  // if there are no images, return empty collection
  if (!recommendationTeaserImages || recommendationTeaserImages.length === 0) return null;

  // find the BASE_WITH image url. If none, return empty collection
  const baseWidthImageUrlIdentifier = '/BASE_WIDTH/';
  const baseWidthImageUrl = recommendationTeaserImages.find(url => url.includes(baseWidthImageUrlIdentifier));
  if (!baseWidthImageUrl) {
    return null;
  }

  const createImageSource = imageSourceCreator(baseWidthImageUrl, baseWidthImageUrlIdentifier);

  return {
    caption: '',
    imageFormats: {
      fourFive: createImageSource('fourFive'),
      fourThree: createImageSource('fourThree'),
      oneOne: createImageSource('oneOne'),
      sixteenNine: createImageSource('sixteenNine'),
      threeTwo: createImageSource('threeTwo'),
      twentyoneNine: createImageSource('twentyoneNine'),
    },
  };
};

function imageSourceCreator(baseUrl: string, baseWidthImageUrlIdentifier: string) {
  return function (key: keyof TeaserImage['imageFormats']): TeaserImageSource {
    return {
      height: 0,
      width: 0,
      url: baseUrl.replace(baseWidthImageUrlIdentifier, `/${imageAspectRatioMapping[key]}/`),
    };
  };
}
