import { useMemo } from 'react';
import { usePersonalizedArticleListLoader } from '@hubcms/feature-personalized-article-list';
import { TeaserData, TeaserDataOrList, isNonTeaserList } from '@hubcms/domain-teaser';
import { mapRecord } from '@hubcms/utils-browser';
import { StoryblockAreas } from '@hubcms/domain-storyblock';

import { useArticleListLoader } from '../utils/useArticleListLoader';

export function useFlattenTeaserLists<A extends StoryblockAreas<TeaserDataOrList>>(areas: A): StoryblockAreas<TeaserData> {
  // this structure is required, because we cannot run hooks in a for-loop
  const a1 = useArticleListLoader(areas);
  const a2 = usePersonalizedArticleListLoader(a1);

  return useMemo(() => mapRecord(a2, area => area.filter(isNonTeaserList)), [a2]);
}
