import { HtmlTeaserData } from '@hubcms/domain-teaser';
import { GHtml } from '@hubcms/domain-teaser-grid';

export function createHtmlGridItem(htmlTeaserData: HtmlTeaserData): GHtml {
  return {
    type: 'html',
    data: {
      html: htmlTeaserData.html,
    },
  };
}
