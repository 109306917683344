import {
  TeaserFragment,
  isArticleTeaserContent,
  isAudioVisualTeaserContent,
  isExternalLinkTeaserContent,
  isTagIntroTeaserContent,
} from '@hubcms/domain-cook';

export function getTeaserIntroduction(teaserData: TeaserFragment): string {
  const deskedIntro = teaserData.deskedIntro;
  const externalListTeaserContent = isExternalLinkTeaserContent(teaserData.content) ? teaserData?.content?.fields?.intro : null;
  const teaserIntro =
    isArticleTeaserContent(teaserData.content) || isTagIntroTeaserContent(teaserData.content)
      ? teaserData.content.teaserIntro?.[0]?.fields?.filter(c => c.name === 'teaserIntro').pop()?.value
      : null;
  const articleIntro =
    isArticleTeaserContent(teaserData.content) || isTagIntroTeaserContent(teaserData.content)
      ? teaserData.content.intro?.[0]?.fields?.find(item => item.name === 'intro')?.value
      : null;
  const audioVisualIntro = isAudioVisualTeaserContent(teaserData.content) ? teaserData.content.fields.intro : null;

  return deskedIntro || externalListTeaserContent || teaserIntro || articleIntro || audioVisualIntro || '';
}
