import { TTeaserGridItem } from '@hubcms/domain-teaser-grid';
import { TrackingData } from '@hubcms/domain-tracking';

export const GMySectionCallToActionType = 'mySectionCallToAction' as const;

export type GMySectionCallToAction = TTeaserGridItem<
  typeof GMySectionCallToActionType,
  {
    text?: string;
    buttonText: string;
    buttonLink: string;
    trackingData?: TrackingData;
  }
>;
