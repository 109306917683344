import { ArticleTeaserData, PlaceholderTeaserData, isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import { GDefaultTeaser } from '@hubcms/domain-teaser-grid';

import { SubsequentAreaOptions } from './types';

type TeaserOptions = SubsequentAreaOptions & {
  isAreaOnePositionTop: boolean;
  isTeaserHighlighted: boolean;
  isLastInArea: boolean;
};

export function createDefaultTeaserData(
  teaserData: ArticleTeaserData | PlaceholderTeaserData,
  { isStackedMobile, hasImages, isTeaserHighlighted, isAreaOnePositionTop, isLastInArea }: TeaserOptions,
) {
  const isPlaceholder = isPlaceholderTeaserData(teaserData);

  if (!isPlaceholder && !hasImages) {
    teaserData.image = null;
  }

  const defaultTeaser: GDefaultTeaser = {
    type: 'default-teaser',
    data: {
      teaserData,
      isPriority: false,
      orientation: {
        xs: isStackedMobile ? 'horizontal' : 'vertical',
        lg: 'vertical',
      },
      size: 'sm',
      isImageHidden: !hasImages,
      isHighlighted: isTeaserHighlighted,
      imageSizes: {
        xs: '50vw',
        lg: isAreaOnePositionTop ? '416px' : '308px',
      },
      hasIntro: false,
      hasDateTime: false,
      headerLevel: 3,
      hasInset: isTeaserHighlighted,
      isFullHeight: true,
    },
    gridProps: {
      column: isStackedMobile ? 'span 2' : 'auto',
      columnMd: 'auto',
      columnLg: 'auto',
      rowLg: 'auto',
      hasInverseMarginSm: isTeaserHighlighted && isStackedMobile,
      hasNoGridDivider: !isStackedMobile && !isLastInArea,
      isSquareSm: !isStackedMobile,
    },
  };

  return defaultTeaser;
}
