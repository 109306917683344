import { NewsletterData } from '@hubcms/domain-newsletter';
import { PartialRequiredTeaser } from '../util-types/partial-required-teaser';

export type NewsletterTeaserData = {
  teaserType: 'newsletter';
  id: string;
} & NewsletterData;

export function createNewsletterTeaserData(
  partialNewsletterData: PartialRequiredTeaser<
    NewsletterTeaserData,
    'newsletter' | 'brand' | 'location' | 'showIcon' | 'suggestions' | 'id'
  >,
): NewsletterTeaserData {
  return {
    teaserType: 'newsletter',
    ...partialNewsletterData,
  };
}
