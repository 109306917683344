import { type GMySectionCallToAction, GMySectionCallToActionType } from '@hubcms/domain-my-section';
import type { MySectionGroupOptions } from '@hubcms/domain-storyblock';
import { isHtmlTeaserData, isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import type { GDefaultTeaser, GHtml, GTitle } from '@hubcms/domain-teaser-grid';
import { createHtmlGridItem } from '../createHtmlGridItem';
import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';
import { isHtmlOrArticleOrPlaceholderTeaserData } from '../../internal/isArticleOrPlaceholderTeaserData';

export const mapMySectionGroup: MapStoryblockFn<MySectionGroupOptions> = (storyblockOptions, teaserAreas) => {
  const isSectionSelected = Boolean(storyblockOptions.selectedSection);

  const numberOfTeasers = isSectionSelected || !storyblockOptions.ctaButtonText ? 5 : 4;
  const items: Array<GHtml | GDefaultTeaser | GMySectionCallToAction> = teaserAreas.groupArea
    .filter(isHtmlOrArticleOrPlaceholderTeaserData)
    .slice(0, numberOfTeasers)
    .map((teaserData, idx) => {
      const isHeadTeaser = idx === 0;
      if (isHtmlTeaserData(teaserData)) {
        return createHtmlGridItem(teaserData);
      }
      const isPlaceholder = isPlaceholderTeaserData(teaserData);
      return {
        type: 'default-teaser',
        data: {
          teaserData,
          imageSizes: {
            xs: isHeadTeaser ? '100vw' : '50vw',
            lg: isHeadTeaser ? '632px' : '308px',
          },
          hasIntro: false,
          hasDateTime: false,
          hasLabel: true,
          orientation: {
            xs: isHeadTeaser ? 'vertical' : 'horizontal',
            md: 'vertical',
          },
          size: {
            xs: isHeadTeaser ? 'md' : 'sm',
            md: 'md',
            lg: isHeadTeaser ? 'lg' : 'sm',
          },
          hasInset: {
            xs: isHeadTeaser,
            lg: false,
          },
          headerLevel: 3,
          trackingData: {
            ...(!isPlaceholder ? teaserData.trackingData : {}),
            clickitemblock: storyblockOptions.title,
            clickitemposition: idx + 1,
          },
        },
        gridProps: isHeadTeaser
          ? {
              columnMd: 'span 2',
              rowLg: 'span 2',
              hasInverseMarginInlineSm: true,
              hasInverseMarginInlineMd: true,
              hasInverseMarginBlockEndSm: true,
            }
          : {},
      };
    });

  const callToAction: GMySectionCallToAction = {
    type: GMySectionCallToActionType,
    data: {
      text: storyblockOptions.ctaActionText,
      buttonText: storyblockOptions.ctaButtonText,
      buttonLink: storyblockOptions.overviewSectionLink,
      trackingData: {
        clickitemblock: storyblockOptions.title,
        clickitemname: 'Select my section',
        clickitemurl: storyblockOptions.overviewSectionLink,
      },
    },
  };

  return {
    key: 'groupArea',
    title: getGroupTitle(storyblockOptions),
    items: items.concat(isSectionSelected ? [] : [callToAction]),
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
  };
};

function getGroupTitle(storyblockOptions: MySectionGroupOptions): GTitle | null {
  if (!storyblockOptions.title) {
    return null;
  }
  if (storyblockOptions.selectedSection) {
    return {
      type: 'title',
      data: {
        text: storyblockOptions.selectedSection.name,
        subLink: {
          text: storyblockOptions.sectionSelectedSubtext,
          url: storyblockOptions.overviewSectionLink,
          target: '_self',
        },
        link: {
          text: storyblockOptions.sectionSelectedActionText,
          url: storyblockOptions.selectedSection.link,
        },
      },
      gridProps: {
        column: '1 / -1',
      },
    };
  }
  return {
    type: 'title',
    data: {
      text: storyblockOptions.title,
      link: {
        text: storyblockOptions.noSectionSelectedActionText,
        url: storyblockOptions.overviewSectionLink,
        target: '_self',
        trackingData: {
          clickitemblock: storyblockOptions.title,
          clickitemname: 'Select my section',
          clickitemurl: storyblockOptions.overviewSectionLink,
        },
      },
    },
    gridProps: {
      column: '1 / -1',
    },
  };
}
