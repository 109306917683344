import {
  AdditionalTeaserContent,
  ImageFieldsFragment,
  PictureRelationFragment,
  RealTeaserContent,
  TeaserFragment,
  isAdditionalTeaserContent,
  isArticleTeaserContent,
  isRealTeaserContent,
  isTagIntroTeaserContent,
} from '@hubcms/domain-cook';
import { TeaserImage, TeaserImageSource } from '@hubcms/domain-teaser';

type CookImageData = {
  caption?: string;
  href?: string;
  content: PictureRelationFragment;
};

export function getTeaserImage(teaserFragment: TeaserFragment): TeaserImage | null {
  const cookImageData = getCookImageData(teaserFragment);
  if (!cookImageData) {
    return null;
  }

  const { caption, image } = cookImageData;

  const teaserImage: TeaserImage = {
    caption,
    imageFormats: createImageFormats(image),
  };
  return teaserImage;
}

type ContentWithTeaserImage = RealTeaserContent | AdditionalTeaserContent;
function isContentWithTeaserImage(content: TeaserFragment['content']): content is ContentWithTeaserImage {
  return isRealTeaserContent(content) || isAdditionalTeaserContent(content);
}

function getCookImageData(teaserFragment: TeaserFragment): { image: CookImageData; caption: string | null } | null {
  const deskedImage = teaserFragment.deskedImage?.[0];

  const teaserImage = isContentWithTeaserImage(teaserFragment.content) ? teaserFragment.content.teaserImage?.[0] : null;
  const firstImage = isTagIntroTeaserContent(teaserFragment.content) ? teaserFragment.content.images?.[0] : null;

  const image = deskedImage || teaserImage || firstImage;
  if (image) {
    const caption = teaserImage?.caption || teaserImage?.content?.fields.caption || null;
    return {
      image,
      caption,
    };
  }

  if (isArticleTeaserContent(teaserFragment.content)) {
    const heroMedia = teaserFragment.content.heroMedia?.[0];
    const heroMediaQuickCaptionObj = heroMedia?.fields?.filter(el => el.name === 'caption')?.pop();
    const heroMediaQuickCaption = heroMediaQuickCaptionObj?.value;
    switch (heroMedia?.content?.__typename) {
      case 'Picture':
        return {
          image: heroMedia as CookImageData,
          caption: heroMediaQuickCaption || heroMedia.content.fields?.caption || null,
        };
      case 'Gallery':
        return {
          image: heroMedia.content.relatedImages?.[0],
          caption: heroMediaQuickCaption || heroMedia.content.fields?.title || null,
        };
      default:
        break;
    }
  }
  return null;
}

function createImageFormats(cueImage: CookImageData): TeaserImage['imageFormats'] {
  const fields = cueImage?.content?.fields;
  return {
    fourFive: createImageFormat(fields.fourFive),
    fourThree: createImageFormat(fields.fourThree),
    oneOne: createImageFormat(fields.oneOne),
    sixteenNine: createImageFormat(fields.sixteenNine),
    threeTwo: createImageFormat(fields.threeTwo),
    twentyoneNine: createImageFormat(fields.twentyoneNine),
  };
}

function createImageFormat(representation: ImageFieldsFragment): TeaserImageSource {
  return {
    height: representation.height,
    width: representation.width,
    url: representation.href_full,
  };
}
