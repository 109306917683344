import { BreakingGroupOptions } from '@hubcms/domain-storyblock';
import { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';

export const createBreakingGroupOptions: CreateStoryblockOptionsFn<BreakingGroupOptions> = ({ group, sectionParams }) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: 'BreakingGroup',
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),
    breakingLabel: getBreakingLabel(groupOptions, sectionParams['article.breaking.label']),
  };
};

function getBreakingLabel(groupOptions: Record<string, string>, sectionParamsDefault = '') {
  if (groupOptions['hideBreakingLabel'] === 'true') {
    return '';
  }
  return groupOptions['breakingTitle'] || sectionParamsDefault;
}
