import { ComponentProps, ElementType, Fragment } from 'react';

import { Ad, CbaAd } from '@hubcms/feature-ads';
import { TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { isCbaAd, useAdPositions } from '@hubcms/utils-ads';
import { TStoryblock } from '@hubcms/domain-storyblock';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';
import cx from 'classnames';
import { isThemed } from '@hubcms/utils-theme';

import { StoryblockListItem } from '../ui/StoryblockListItem';

import styles from './storyblock-list.module.scss';

type StoryblockListProps = {
  storyblocks: TStoryblock[];
  adPositions?: number[];
  adFormats?: string[];
  adSlotPrefix?: string;
  isAside?: boolean;
  gridExtensions?: TeaserGridExtensions;
  teaserPropsOptions: TeaserPropsOptions;
  withFirstBlockTopMargin?: boolean;
  getAdElement?: (
    index: number,
  ) => ElementType<Pick<ComponentProps<typeof Ad>, 'adFormat' | 'adSlot' | 'adSlotPrefix' | 'className'>>;
};

export function StoryblockList({
  storyblocks,
  adPositions = [],
  adFormats = [],
  adSlotPrefix,
  isAside = false,
  gridExtensions,
  teaserPropsOptions,
  withFirstBlockTopMargin = false,
  getAdElement = () => Ad,
}: StoryblockListProps) {
  const { getAdFormatForPosition } = useAdPositions(adPositions, adFormats);

  return (
    <>
      {storyblocks.map(({ theme, groupId, storyblockOptions, unflattenedTeaserAreas: teaserAreas, createGridData }, idx) => {
        const adFormat = getAdFormatForPosition(idx + 1);
        const AdElement = getAdElement(idx);
        const hideOnMobile = storyblockOptions.isHiddenOnMobile;

        return (
          <Fragment key={groupId}>
            <StoryblockListItem
              theme={theme}
              groupId={groupId}
              storyblockOptions={storyblockOptions}
              hasNoPaddingBlock={storyblockOptions.type === 'ArticleGroup' || (isAside && !isThemed(theme))}
              hasNoPaddingInline={isAside && !isThemed(theme)}
              hasTopMargin={withFirstBlockTopMargin && idx === 0 && isThemed(theme)}
              isTransparentTheme={isAside}
              teaserGrid={{
                unflattenedTeaserAreas: teaserAreas,
                createGridData,
                gridExtensions,
              }}
              teaserPropsOptions={teaserPropsOptions}
            />
            {/* increasing adSlot index by 1 because of the leaderboard at the top of the page. also, the ad positions in CUE begin their count at 1 instead of 0. */}
            {adFormat &&
              (isCbaAd(adFormat) ? (
                <CbaAd
                  className={cx(styles.ad, {
                    [styles.hideOnMobile]: hideOnMobile,
                  })}
                />
              ) : (
                <AdElement
                  adFormat={adFormat}
                  adSlot="b"
                  adSlotPrefix={adSlotPrefix}
                  className={cx(styles.ad, {
                    [styles.hideOnMobile]: hideOnMobile,
                  })}
                />
              ))}
          </Fragment>
        );
      })}
    </>
  );
}
