import {
  ArticleTeaserData,
  HtmlTeaserData,
  NewsletterTeaserData,
  PlaceholderTeaserData,
  TeaserData,
  isNewsletterTeaserData,
} from '@hubcms/domain-teaser';
import { isHtmlOrArticleOrPlaceholderTeaserData } from './isArticleOrPlaceholderTeaserData';

export function isCustomizableStoryblockTeaserData(
  teaserData: TeaserData,
): teaserData is HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData | NewsletterTeaserData {
  return isHtmlOrArticleOrPlaceholderTeaserData(teaserData) || isNewsletterTeaserData(teaserData);
}
