import { ContentType } from '@hubcms/domain-cook';
import { TrackingData } from '@hubcms/domain-tracking';

import { PartialRequiredTeaser } from '../util-types/partial-required-teaser';
import { TeaserImage, TeaserImageSource } from './teaser-image';

type TeaserAuthor = {
  image: TeaserImageSource | null;
  name: string;
};

export type ArticleTeaserData = {
  teaserType: 'article';
  contentType: ContentType | '';
  authors: TeaserAuthor[];
  dateTime: string;
  duration: string;
  hasHeroVideo: boolean;
  canHideExternalIcon: boolean;
  id: string;
  image: TeaserImage | string | null;
  introduction: string;
  isDateToday: boolean;
  isExternal: boolean;
  isPremium: boolean;
  label: string;
  list?: {
    id: string;
    sourceType: string;
  };
  prefix: string;
  subLabel: string;
  title: string;
  theme?: string;
  url: string;
  trackingData?: TrackingData;
  teaserLabel: string;
  teaserShowLiveIcon: boolean;
};

export function createArticleTeaserData(
  partialArticleTeaserData: PartialRequiredTeaser<ArticleTeaserData, 'contentType' | 'id' | 'title' | 'url'>,
): ArticleTeaserData {
  return {
    teaserType: 'article',
    authors: [],
    dateTime: '',
    duration: '',
    hasHeroVideo: false,
    canHideExternalIcon: false,
    image: null,
    introduction: '',
    isDateToday: false,
    isExternal: false,
    isPremium: false,
    label: '',
    prefix: '',
    subLabel: '',
    theme: 'none',
    trackingData: {},
    teaserLabel: '',
    teaserShowLiveIcon: false,
    ...partialArticleTeaserData,
  };
}
