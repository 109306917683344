import { StoryblockOptions } from '@hubcms/domain-storyblock';
import { CreateStoryblockOptionsFn } from '../domain/create-storyblock-options-fn';
import { createArticleGroupOptions } from '../internal/storyblock-options/createArticleGroupOptions';
import { createBreakingGroupOptions } from '../internal/storyblock-options/createBreakingGroupOptions';
import { createCustomizableGroupOptions } from '../internal/storyblock-options/createCustomizableGroupOptions';
import { createHtmlGroupOptions } from '../internal/storyblock-options/createHtmlGroupOptions';
import { createLifestyleGroupOptions } from '../internal/storyblock-options/createLifestyleGroupOptions';
import { createMySectionGroupOptions } from '../internal/storyblock-options/createMySectionGroupOptions';
import { createOpeningGroupOptions } from '../internal/storyblock-options/createOpeningGroupOptions';
import { createOpinionGroupOptions } from '../internal/storyblock-options/createOpinionGroupOptions';
import { createRegularGroupOptions } from '../internal/storyblock-options/createRegularGroupOptions';
import { createVisualStoriesGroupOptions } from '../internal/storyblock-options/createVisualStoriesGroupOptions';

export const getStoryblockOptions: CreateStoryblockOptionsFn<StoryblockOptions> = params => {
  // Destructuring is needed for groups that have multiple areas
  const { group } = params;

  switch (group.__typename) {
    case 'ArticleGroup':
    case 'SponsoredAsideGroup':
      return createArticleGroupOptions(params);
    case 'BreakingGroup':
      return createBreakingGroupOptions(params);
    case 'CustomizableStoryblockGroup':
      return createCustomizableGroupOptions({ ...params, group });
    case 'HtmlGroup':
      return createHtmlGroupOptions(params);
    case 'LifestyleGroup':
      return createLifestyleGroupOptions(params);
    case 'MySectionGroup':
      return createMySectionGroupOptions(params);
    case 'OpeningGroup':
      return createOpeningGroupOptions({ ...params, group });
    case 'OpinionGroup':
      return createOpinionGroupOptions(params);
    case 'RegularGroup':
    case 'SponsoredGroup':
      return createRegularGroupOptions(params);
    case 'VisualStoriesGroup':
      return createVisualStoriesGroupOptions(params);
    default:
      return { type: 'unknown', isHiddenOnMobile: false };
  }
};
