import { AdditionalTeaserContent, RealTeaserContent, isArticleTeaserContent } from '@hubcms/domain-cook';

export function mapAuthors(content: RealTeaserContent | AdditionalTeaserContent) {
  if (!isArticleTeaserContent(content) || !content.authors) {
    return [];
  }
  return content.authors.map(author => {
    const headshotImage = author.profile?.content.headshotImage?.[0]?.content?.fields?.oneOne;
    return {
      name: author.name,
      image: headshotImage
        ? {
            url: headshotImage?.href_full,
            height: headshotImage?.height,
            width: headshotImage?.width,
          }
        : null,
    };
  });
}
