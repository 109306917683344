import { MySectionGroupOptions } from '@hubcms/domain-storyblock';
import { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';

export const createMySectionGroupOptions: CreateStoryblockOptionsFn<MySectionGroupOptions> = ({ group, sectionParams }) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: 'MySectionGroup',
    title: groupOptions['groupTitle'],
    overviewSectionLink: groupOptions['groupSectionLink'],
    sectionSelectedActionText: sectionParams['section.group.readmore.text'],
    sectionSelectedSubtext: groupOptions['changeSectionLinkText'],
    noSectionSelectedActionText: groupOptions['noSectionSelectedLinkText'],
    ctaButtonText: groupOptions['ctaButtonText'],
    ctaActionText: groupOptions['ctaActionText'],
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),
  };
};
