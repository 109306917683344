import { PartialRequiredTeaser } from '../util-types/partial-required-teaser';

export type HtmlTeaserData = {
  teaserType: 'html';
  id: string;
  html: string;
};

export function createHtmlTeaserData(partialHtmlTeaserData: PartialRequiredTeaser<HtmlTeaserData, 'id'>): HtmlTeaserData {
  return {
    teaserType: 'html',
    html: '',
    ...partialHtmlTeaserData,
  };
}
