import { TeaserData } from './teaser-data';
import { TeaserList } from './teaser-list';

export type TeaserDataOrList = TeaserData | TeaserList;

export function isTeaserList(teaserDataOrList?: TeaserDataOrList | null): teaserDataOrList is TeaserList {
  return !!teaserDataOrList && teaserDataOrList.teaserType === 'list';
}

export function isNonTeaserList(teaserDataOrList?: TeaserDataOrList | null): teaserDataOrList is TeaserData {
  return !!teaserDataOrList && teaserDataOrList.teaserType !== 'list';
}
