import { TeaserFragment, isArticleTeaserContent } from '@hubcms/domain-cook';

export function getTeaserHeroVideo(teaserData: TeaserFragment): { duration: string } | null {
  if (!isArticleTeaserContent(teaserData.content)) {
    return null;
  }

  const heroMedia = teaserData.content.heroMedia?.[0];
  if (heroMedia?.content?.__typename === 'Video') {
    return { duration: heroMedia.content?.fields?.duration ?? '' };
  }

  return null;
}
