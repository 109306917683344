import { NewsletterTeaserData } from '@hubcms/domain-teaser';
import { GNewsletter } from '@hubcms/domain-teaser-grid';

export function createNewsletterItem({ newsletter, showIcon, location, brand, suggestions }: NewsletterTeaserData): GNewsletter {
  return {
    type: 'newsletter',
    data: {
      newsletter,
      showIcon,
      location,
      brand,
      suggestions,
    },
  };
}
