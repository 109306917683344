import { TeaserFragment, isArticleTeaserContent, isTagIntroTeaserContent } from '@hubcms/domain-cook';

export function getTeaserTitle(teaserData: TeaserFragment): string {
  const deskedTitle = teaserData.deskedTitle;
  const teaserTitle =
    isArticleTeaserContent(teaserData.content) || isTagIntroTeaserContent(teaserData.content)
      ? teaserData.content.teaserHeadline?.[0]?.fields?.filter(c => c.name === 'teaserHeadline').pop()?.value
      : null;
  return deskedTitle || teaserTitle || teaserData.content?.title;
}
