import { ListIdentifiers, mapRecommendationTeasersToTeaserData } from '@hubcms/data-access-capitan';
import { RecommendationsResponse } from '@hubcms/domain-capitan';
import { TeaserDataOrList } from '@hubcms/domain-teaser';
import { appendDefaultArticleTrackingData } from '@hubcms/utils-teaser';

import { createPlaceholders } from './create-placeholders';
import { isPersonalizedList } from '../domain/personalized-list';

export const mapPersonalizedArticleLists = (recommendationsResponse: RecommendationsResponse, addPlaceholders = false) => {
  return (teasers: TeaserDataOrList[]): TeaserDataOrList[] => {
    return teasers.flatMap(teaserDataOrList => {
      if (!isPersonalizedList(teaserDataOrList)) {
        return [teaserDataOrList];
      }

      // find matching personalized list in recommendations data
      const personalizedListData = recommendationsResponse.lists.find(
        ({ name: personalizedListName }) => personalizedListName === teaserDataOrList.title,
      );

      // map recommendations teasers to teaser fragments
      if (personalizedListData) {
        const listIdentifiers: ListIdentifiers = {
          recommender: recommendationsResponse.recommender,
          listId: teaserDataOrList.id,
        };
        return mapRecommendationTeasersToTeaserData(personalizedListData.teasers, listIdentifiers, {
          itemdescription: personalizedListData.name,
          recommendationsource: recommendationsResponse.recommender,
          recommendationrequestid: recommendationsResponse.requestId,
          recommendationsegmentgroup: recommendationsResponse.modelVersion,
        }).map(appendDefaultArticleTrackingData);
      }

      // map placeholders
      if (addPlaceholders) {
        return createPlaceholders(teaserDataOrList.params.maxCount);
      }

      return [];
    });
  };
};
