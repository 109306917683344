import { PartialRequiredTeaser } from '../util-types/partial-required-teaser';

export type PlaceholderTeaserData = {
  id: string;
  teaserType: 'placeholder';
};

export function createPlaceholderTeaserData(
  partialPlaceholderTeaserData: PartialRequiredTeaser<PlaceholderTeaserData, 'id'>,
): PlaceholderTeaserData {
  return {
    teaserType: 'placeholder',
    ...partialPlaceholderTeaserData,
  };
}
