import { OpeningGroup } from '@hubcms/domain-cook';
import { OpeningGroupOptions } from '@hubcms/domain-storyblock';
import { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { getGroupLink } from '../../lib/getGroupLink';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';
import { getListLayout } from '../getListLayout';

export const createOpeningGroupOptions: CreateStoryblockOptionsFn<OpeningGroupOptions, OpeningGroup> = ({
  group,
  sectionParams,
}) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);
  const newsAreaOptions = mapToGroupOptionsRecord(group.newsAreaOptions);
  const listAreaOptions = mapToGroupOptionsRecord(group.listAreaOptions);

  return {
    type: 'OpeningGroup',
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),

    theme: groupOptions['groupTheme'],
    isIntroHidden: groupOptions['hideIntro'] === 'true',

    listAreaOptions: {
      title: listAreaOptions['areaTitle'],
      actionLink: getGroupLink(listAreaOptions, 'areaTagLink', 'areaSectionLink'),
      actionText: sectionParams['section.group.readmore.text'],
      listLayout: getListLayout(listAreaOptions['teaserLayout']),
      hasImages: false,
      isListWithMobileSlider: false,
    },
    newsAreaOptions: {
      title: newsAreaOptions['areaTitle'],
      actionLink: getGroupLink(newsAreaOptions, 'areaTagLink', 'areaSectionLink'),
      actionText: sectionParams['section.group.readmore.text'],
    },
  };
};
